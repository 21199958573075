import React from 'react';
import { IXData, XPlacementKey } from '../types/x-preview.type';
import { XPromotedTweetPreview } from '../components';

export const XFactory = (placementKey: XPlacementKey, xData: IXData) => {
    let PreviewComponent: React.FC<IXData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'x-promoted-tweet':
            PreviewComponent = XPromotedTweetPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...xData} />;
};
