import React from 'react';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';
import SnapchatWrapper from './snapchat-wrapper';
import { ISnapchatData } from '../types/snapchat-preview.type';

const SnapchatSinglePreview: React.FC<ISnapchatData> = ({ cta, items = [] }) => {
    const { link, asset, headline } = items?.[0] || {};
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    return <SnapchatWrapper asset={asset} cta={cta} link={formattedLink} headline={headline}></SnapchatWrapper>;
};

export default SnapchatSinglePreview;
