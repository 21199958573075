import React from 'react';
import BrandSettings from 'components/data/BrandSettings';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IXData } from '../types/x-preview.type';
import verified from '../images/verified.svg';
import actions from '../images/actions.svg';
import dots from '../images/dots.svg';
import FallbackLogo from '../../../../../../../../static/fallback-logo-cape.png';

import '../styles/x-promoted-tweet-preview.scss';

const XPromotedTweetPreview: React.FC<IXData> = ({ message, items }) => {
    const { website_url, asset, description } = items?.[0] || {};
    const advertiser = BrandSettings.getAdvertiserData();

    return (
        <div className="x-promoted-tweet">
            <div className="x-promoted-tweet__left">
                <div className="x-promoted-tweet__left__avatar-wrapper">
                    <img src={advertiser?.logo || FallbackLogo} className="x-promoted-tweet__left__avatar"></img>
                </div>
            </div>
            <div className="x-promoted-tweet__right">
                <div className="x-promoted-tweet__right__header">
                    <div className="x-promoted-tweet__right__header__advertiser">{advertiser?.name || 'Cape'}</div>
                    <div className="x-promoted-tweet__right__header__verified">
                        <img src={verified} alt="Verified" />
                    </div>
                    <div className="x-promoted-tweet__right__header__at">@{advertiser?.name || 'cape'}</div>

                    <div className="x-promoted-tweet__right__header__ad">Ad</div>
                    <div className="x-promoted-tweet__right__header__dots">
                        <img src={dots} alt="Dots" />
                    </div>
                </div>

                <div className="x-promoted-tweet__right__message">{message}</div>
                {asset ? (
                    <div className="x-promoted-tweet__right__asset-wrapper">{asset}</div>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="x-promoted-tweet__right__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
                {description && <div className="x-promoted-tweet__media-message">{description}</div>}
                <div className="x-promoted-tweet__right__from">From {website_url || 'bycape.io'}</div>
                <div className="x-promoted-tweet__right__bottom-actions">
                    <img src={actions} alt="Actions" />
                </div>
            </div>
        </div>
    );
};

export default XPromotedTweetPreview;
